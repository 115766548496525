import React, { Fragment } from "react";
import { statusRenderer } from "../value-renderers";
import { ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { theme } from "../theme";
import { tableIcons } from "../table-icons";
import { urgentStyle } from "./urgentStyle";
import ReferralsTable from "material-table";
import { getDateTime, getDescription, getReferee, getReferrer } from "./utils";

const FhirReferralsList = ({
  showAllReferrals,
  referrals,
  onReferralClick,
}) => {
  const columns = [
    {
      title: "Urgent?",
      render: (referral) => {
        const { urgent } = referral;
        return <div style={urgentStyle(urgent)}>{urgent ? "YES" : "NO"}</div>;
      },
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Referrer",
      render: (referral) => <div>{getReferrer(referral)?.display}</div>,
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Referee",
      render: (referral) => <div>{getReferee(referral)?.display}</div>,
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Status",
      render: (referral) => (
        <div>{statusRenderer(referral.status, referral)}</div>
      ),
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Comment",
      render: (referral) => <div>{referral.statusComment || "-----"}</div>,
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Date/Time",
      render: (referral) => <div>{getDateTime(referral)}</div>,
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
  ];

  columns.splice(columns.length - 1, 0, {
    title: "Description",
    render: (referral) => <div>{getDescription(referral)}</div>,
    cellStyle: { textAlign: "center" },
    headerStyle: { textAlign: "center" },
  });

  if (referrals) {
    return (
      <ThemeProvider theme={theme}>
        <ReferralsTable
          title={""}
          columns={columns}
          data={referrals}
          icons={tableIcons}
          onRowClick={(e, referral) => onReferralClick(referral)}
          options={{
            emptyRowsWhenPaging: true,
            minBodyHeight: "100%",
            maxBodyHeight: "100%",
            padding: "dense",
            pageSize: 10,
            search: true,
          }}
        />
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <div>
          <CircularProgress />
        </div>
      </ThemeProvider>
    );
  }
};

export default FhirReferralsList;
