import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import auth from "../utils/auth";
import colors from "../utils/colors";
import { timerCheckFocus, timeoutSessionMessageFlag } from "../utils/constants";
import TimeoutMessage from "./TimeoutMessage";
import { endHeartbeat } from "../utils";
import { AuthContext } from "../AuthProvider";
import { get } from "lodash";
import { aclsLocalStorageKey } from "../utils/permissions/permission.utils";

const theme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: colors.baseBackground,
      },
    },
    MuiIconButton: {
      label: {
        fontSize: "16px",
      },
      root: {
        position: "absolute",
        right: "8%",
        top: "5px",
      },
    },
    MuiPopover: {
      paper: {
        left: "86% !important",
        top: "50px !important",
      },
    },
    MuiSvgIcon: {
      root: {
        height: "35px",
        marginLeft: "5px",
        width: "35px",
      },
    },
  },
});

let timeoutId = null;

const Header = () => {
  const token = useContext(AuthContext);
  const history = useHistory();
  const [isAuth] = useState(auth.isAuthenticated(token));
  const [focusLost, setFocusLost] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);

  const onBlur = useRef((e) => {
    e.preventDefault();
    e.stopPropagation();
    timeoutId = setTimeout(() => onFocusLost(), timerCheckFocus);
  });

  function onFocusLost() {
    window.removeEventListener("focus", onFocus);
    window.removeEventListener("blur", onBlur.current);
    setFocusLost(true);
    if (timeoutId != null) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  }

  function onFocus(e) {
    setFocusLost(false);
    if (timeoutId != null) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  }

  useEffect(() => {
    setUser(auth.user());
    const _onBlur = onBlur.current;
    if (timeoutSessionMessageFlag) {
      if (isAuth) {
        window.addEventListener("focus", onFocus, false);
        window.addEventListener("blur", _onBlur, false);
      }
      return () => {
        window.removeEventListener("focus", onFocus);
        window.removeEventListener("blur", _onBlur);
      };
    }
    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    };
  }, [isAuth]);

  // useEffect(
  //   () => {
  //     console.log("Header component has mounted or updated.");
  //     let storedToken = auth.getTokenFromStorage();
  //     if (!storedToken) {
  //       auth.logout('NO_TOKEN');
  //     }
  //     else {
  //       auth.logoutIfRequired(storedToken);
  //     }
  //   }
  // )

  const handleMenu = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleHome = () => {
    history.push("/");
  };

  const handleLogout = () => {
    localStorage.removeItem(aclsLocalStorageKey);
    endHeartbeat();
    auth.logout("UI_MANUAL");
    setOpen(false);
  };

  const handleCloseSession = () => {
    setFocusLost(false);
    auth.logout("UI_INACTIVITY");
  };

  const handleContinueSession = () => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur.current);
    setFocusLost(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" className="container-header">
        <Container className="container-logo">
          <img
            alt="logo"
            className="logo-img"
            src={process.env.REACT_APP_BRANDING_LOGO}
            onClick={handleHome}
          />
        </Container>
        {isAuth && (
          <Container>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Grid
                container
                direction="column"
                spacing={1}
                alignItems="flex-end"
                style={{ marginRight: "0.5rem" }}
              >
                <Grid item>{get(user, "userinfo.preferred_username")}</Grid>
                <Grid item style={{ fontSize: "smaller" }}>
                  {get(user, "userinfo.orgDisplayName") ||
                    get(user, "userinfo.orgName")}
                </Grid>
              </Grid>
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </Menu>
          </Container>
        )}
      </AppBar>
      {timeoutSessionMessageFlag && focusLost && isAuth && (
        <TimeoutMessage
          handleClose={handleCloseSession}
          handleContinue={handleContinueSession}
        />
      )}
    </ThemeProvider>
  );
};

export default Header;
