import React, { useEffect, useState } from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core'
import {
  Close,
  Done
} from '@material-ui/icons'
import { timerWaitAnswer } from '../utils/constants'
import colors from '../utils/colors'
import '../styles/index.css'

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: colors.baseBlue,
        "&:hover": {
          backgroundColor: colors.baseBlue,
          opacity: '0.8'
        }
      }
    },
    MuiDialogActions: {
      root: {
        paddingRight: '25px'
      }
    },
    MuiLinearProgress: {
      barColorPrimary: {
        backgroundColor: colors.baseBlue
      }
    },
    MuiSvgIcon: {
      root: {
        height: '20px',
        width: '20px'
      }
    }
  }
})

const LinearCountdown = ({value}) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      {/*<Box minWidth={35}>
        <Typography component={'span'} variant="body2" color="textSecondary">
          {`${Math.round(value,)}%`}
        </Typography>
      </Box>
      */}
    </Box>
  );
}

const TimeoutMessage = ({
  handleClose,
  handleContinue
}) => {
  const [countdown, setCountdown] = useState(0)
  const [timer, setTimer] = useState(timerWaitAnswer / 1000)
  const [limitTime] = useState(timerWaitAnswer / 1000)

  useEffect(() => {
    const i = setInterval(() => {
      setCountdown((c) => c + 1)
      setTimer((c) => c - 1)
    }, 1000)
    return () => clearInterval(i)
  }, [])
    
  const millisToMinutesAndSeconds = (millis) => {
    const minutes = Math.floor(millis / 60000)
    const seconds = ((millis % 60000) / 1000).toFixed(0)
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds
  }

  let time
  if(countdown < limitTime) {
    time = millisToMinutesAndSeconds(timer * 1000)
  } else {
    handleClose()
  }

  return (
    <ThemeProvider theme={theme}>
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">Your window has been inactive</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you want to continue your session with My Care Circle?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          className="timeout-message-button"
          color="secondary"
          startIcon={<Close fontSize="small" />}
          variant="contained"
        >
          No
        </Button>
        <Button
          onClick={handleContinue}
          className="timeout-message-button"
          color="primary"
          startIcon={<Done fontSize="small" />}
          variant="contained"
        >
          Yes
        </Button>
      </DialogActions>
      <div className="timer-label">
        Your session will expire in {time}
        <LinearCountdown value={(countdown/limitTime) * 100} />
      </div>
    </Dialog>    
    </ThemeProvider>
  )
}

export default TimeoutMessage
