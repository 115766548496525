import React, { useState } from "react";
import Layout from "./Layout";
import UsersHome from "./Admin/Users/UsersHome";
import InviteTable from "./Admin/Users/InviteTable";
import { makeStyles } from "@material-ui/core/styles";
import { Tab, Tabs, Box, Typography } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
  viewer: {
    position: "absolute",
    width: "80%",
    top: "150px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  tabsNav: {
    display: "flex",
    overflow: "hidden",
    minHeight: "48px",
    maxWidth: "100%",
    position: "fixed",
    top: "110px",
    background: "white",
    width: "100%",
    zIndex: "999",
  },
}));

export default function UserManagement() {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout className={classes.root}>
      <div className={classes.tabsNav}>
        <Tabs value={value} onChange={handleChange} fullWidth>
          <Tab label="Users" {...a11yProps(0)} />
          <Tab label="Invites" {...a11yProps(1)} />
        </Tabs>
      </div>
      <div className={classes.viewer}>
        <TabPanel value={value} index={0}>
          <UsersHome />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InviteTable />
        </TabPanel>
      </div>
    </Layout>
  );
}
