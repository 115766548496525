import React, { Fragment } from "react";
import { statusRenderer } from "../value-renderers";
import { ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { theme } from "../theme";
import { tableIcons } from "../table-icons";
import ReferralsTable from "material-table";
import AssessmentTable from "material-table";

const AssessmentsList = ({
  showAllAssessments,
  assessments,
  onAssessmentClick,
}) => {
  const columns = [
    {
      title: "Title",
      render: (assessment) => (
        <div>{assessment?.content?.[0]?.attachment?.title ?? ""}</div>
      ),
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Author",
      render: (assessment) => (
        <div>{assessment?.author?.[0]?.display ?? ""}</div>
      ),
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
  ];

  if (assessments) {
    return (
      <ThemeProvider theme={theme}>
        <AssessmentTable
          title=""
          columns={columns}
          data={assessments}
          icons={tableIcons}
          onRowClick={(e, data) => onAssessmentClick(data)}
          options={{
            emptyRowsWhenPaging: false,
            maxBodyHeight: "100%",
            padding: "dense",
            pageSize: 10,
            search: true,
            sorting: true,
          }}
        />
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <div>
          <CircularProgress />
        </div>
      </ThemeProvider>
    );
  }
};

export default AssessmentsList;
