import React, { useEffect, useState } from "react";
import moment from "moment";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Button, Grid, TextField } from "@material-ui/core";
import colors from "../../utils/colors";
import "../../styles/index.css";
import auth from "../../utils/auth";
import { checkAclValidation } from "../../utils/permissions/permission.utils";
import mainAcls from "../../utils/permissions/mainAcls";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        background: colors.baseBlue,
        color: colors.gray01,
        fontSize: "12px",
        marginLeft: "10px",
        position: "relative",
        top: "15px",
        "&:hover": {
          backgroundColor: colors.baseBlue,
          opacity: "0.8",
        },
        "&:disabled": {
          color: colors.gray03,
        },
      },
    },
    MuiFormControl: {
      root: {
        //margin: '15px 10px auto 15px',
        //maxWidth: '120px'
      },
    },
    MuiFormHelperText: {
      root: {
        color: colors.baseBlue,
        fontSize: "11px",
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.baseGray,
        fontSize: "14px",
      },
    },
    MuiInput: {
      root: {
        background: colors.gray02,
      },
      underline: {
        "&:after": {
          borderBottom: `2px solid ${colors.baseBlue}`,
        },
      },
    },
    MuiInputBase: {
      input: {
        color: `${colors.black} !important`,
        fontSize: "14px !important",
      },
    },
    MuiInputLabel: {
      formControl: {
        zIndex: "1",
      },
      root: {
        "&$focused": {
          color: colors.baseGray,
        },
      },
    },
  },
});

const PatientFilters = ({
  filter,
  handleChange,
  handleSearchPatient,
  handleNewPatient,
}) => {
  const today = moment(new Date()).format("ll");
  const [orgCanProvision, setOrgCanProvision] = useState(false);

  useEffect(() => {
    (async () => {
      setOrgCanProvision(await auth.orgCanProvision());
    })();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid container className="container-filter" direction="row" spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <Grid
            container
            direction="row"
            className="required-fields"
            spacing={1}
            justify="flex-start"
          >
            <Grid item xs={4} sm={4} md={5}>
              <TextField
                className="filter-input-id"
                id="patientId"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ maxLength: 50 }}
                label="Patient MRN"
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={7} zeroMinWidth>
              <TextField
                className="filter-input-lastname"
                id="lastName"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Last Name"
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <div>
              <span className="required-label">
                * Please provide at least one of these
              </span>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Grid container direction="row" spacing={2} justify="flex-start">
            <Grid item xs={4}>
              <TextField
                className="filter-input-firstname"
                id="firstName"
                InputLabelProps={{
                  shrink: true,
                }}
                label="First Name"
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="birthDate"
                InputProps={{ inputProps: { max: today } }}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Birth Date"
                onChange={(e) => handleChange(e)}
                type="date"
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} justify="flex-start">
            <Grid item xs={4} className="patient-search-button">
              <Button
                disabled={!filter.lastName && !filter.patientId}
                onClick={handleSearchPatient}
              >
                Search
              </Button>
            </Grid>
            {checkAclValidation({
              acls: [mainAcls.MAIN.PATIENT.ADD],
            }) ? (
              <Grid item xs={4} className="patient-add-button">
                <Button onClick={handleNewPatient} disabled={!orgCanProvision}>
                  Add Patient
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default PatientFilters;
