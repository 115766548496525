import { ConfigAPI } from "../../services/ConfigAPI";
import { TENANT_CONFIGURATION_KEY } from "../../utils/constants";

let TENANT_CONFIGURATION = null;

export class TenantConfigurationStore {
  static async init() {
    if (TENANT_CONFIGURATION === null) {
      TENANT_CONFIGURATION = await ConfigAPI.getTenantConfig();
      sessionStorage.setItem(
        TENANT_CONFIGURATION_KEY,
        JSON.stringify(TENANT_CONFIGURATION)
      );
    }
  }

  static async getTenantConfiguration() {
    if (TENANT_CONFIGURATION === null) {
      TENANT_CONFIGURATION = sessionStorage.getItem(TENANT_CONFIGURATION_KEY);
      if (TENANT_CONFIGURATION) {
        TENANT_CONFIGURATION = JSON.parse(TENANT_CONFIGURATION);
      } else {
        await TenantConfigurationStore.init();
      }
    }
    return TENANT_CONFIGURATION;
  }
}
