import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReferralUtils } from "../../../services";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReferralsAPI from "../../../services/ReferralsAPI";
import FhirReferralDocumentSelectionDialog from "./FhirReferralDocumentSelectionDialog";

function FhirReferralDocumentSelectionDialogContainer({
  open,
  handleSave,
  handleDocumentUploaded,
  handleClose,
  patient,
  referral,
  referralDocuments,
  patientDocuments,
}) {
  // const loadData = useCallback(async () => {
  //   const result = await ReferralUtils.getReferralWithDocuments(
  //     patientId,
  //     referralId,
  //     props.documents,
  //     props.referral
  //   );
  //   setReferral(result.referral);
  //   setDocuments(result.documents);
  // }, [patientId, props.documents, props.referral, referralId]);

  // useEffect(() => {
  //   loadData();
  // }, [patientId, referralId, props.referral, props.documents, loadData]);

  const handleDocumentSave = async (documentsToLink) => {
    console.log("DEBUG documentsToLink: ", documentsToLink);
    try {
      await handleSave(documentsToLink);
      handleClose();
    } catch (error) {
      return error.message;
    }
  };

  if (open && handleSave && handleClose && referral && referralDocuments) {
    return (
      <FhirReferralDocumentSelectionDialog
        open={open}
        handleSave={handleDocumentSave}
        handleClose={handleClose}
        referral={referral}
        referralDocuments={referralDocuments}
        patientDocuments={patientDocuments}
        handleDocumentUploaded={handleDocumentUploaded}
        patient={patient}
      />
    );
  }
  return (
    <Dialog open={open && (!referral || !referralDocuments)}>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
}

FhirReferralDocumentSelectionDialogContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleDocumentUploaded: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  referralId: PropTypes.string.isRequired,
  referral: ReferralUtils.REFERRAL_DETAIL_SHAPE,
  referralDocuments: PropTypes.arrayOf(PropTypes.object),
  patientDocuments: PropTypes.arrayOf(PropTypes.object),
};

export default FhirReferralDocumentSelectionDialogContainer;
