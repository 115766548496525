import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import colors from "../../../utils/colors";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: "48%",
        position: "absolute",
        bottom: "10px",
      },
      svg: {
        color: colors.baseBlue,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    // minWidth: 500,
  },
  checkBox: {
    margin: theme.spacing(1),
    marginBottom: 0,
  },
  right: {
    marginLeft: "auto",
  },
  cancelButton: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft: "auto",
  },
  searchButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
  },
  assessmentIframe: {
    width: "100%",
    // height: "100%",
    height: "100vh !important",
    // overflowY: "hidden",
    // overflowX: "hidden",
    border: 0,
    // marginBottom: "10px",
  },
}));

function NewAssessmentDialog({
  open,
  handleClose,
  assessmentId,
  token,
  url,
  assessmentName,
}) {
  const classes = useStyles();

  const [showLoader, setShowLoader] = useState(true);

  const iframeRef = useRef < HTMLIFrameElement > null;

  const iframeUrl = useMemo(() => {
    // const url = new URL(`${ASSESSMENTS_BASE_URL}/survey/index/sid/${assessmentId}`);

    // url.searchParams.append("newtest", "Y");

    // const redirectUrl = new URL(window.location.href);
    // redirectUrl.search = "";
    // redirectUrl.hash = "";
    // redirectUrl.pathname = `/survey-saved`;
    // url.searchParams.append("redirect", redirectUrl.toString());

    // if (token) {
    //   url.searchParams.append("token", token);
    // }

    // return url.toString();
    console.log("DEBUG url:", url);
    return url;
  }, [assessmentId, token]);

  const handleShowLoader = () => {
    setShowLoader(true);
  };

  const handleHideLoader = () => {
    setShowLoader(false);
  };

  const onIframeLoad = () => {
    handleHideLoader();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        PaperProps={{
          sx: {
            width: "100%",
            height: `calc(100vh - 100px)`,
          },
        }}
        aria-labelledby="assessment-dialog"
      >
        {/* <DialogTitle id="assessment-dialog-title">Assessment</DialogTitle> */}
        <DialogTitle id="assessment-selection-dialog-title">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{assessmentName}</Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          {showLoader ? <CircularProgress /> : null}
          <iframe
            className={classes.assessmentIframe}
            // ref={iframeRef}
            hidden={showLoader}
            key={iframeUrl}
            src={iframeUrl}
            title="Running assessment"
            onLoad={onIframeLoad}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

// AssessmentDialog.propTypes = {
// open: PropTypes.bool.isRequired,
// handleClose: PropTypes.func.isRequired,
// handleSave: PropTypes.func.isRequired,
// };

export default NewAssessmentDialog;
