import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import colors from "../../../utils/colors";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  TextField,
} from "@material-ui/core";
import { isArray } from "lodash";
import FhirServicesTable from "./FhirServicesTable";
import FhirRefer from "./FhirRefer";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axiosInstance from "../../../utils/FhirAxiosHelper";
import FhirReferralsAPI from "../../../services/FhirReferralsAPI";
import { parseBundleToServices } from "./utils";
const API = FhirReferralsAPI;

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: "48%",
        position: "absolute",
        bottom: "10px",
      },
      svg: {
        color: colors.baseBlue,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    // minWidth: 500,
  },
  checkBox: {
    margin: theme.spacing(1),
    marginBottom: 0,
  },
  right: {
    marginLeft: "auto",
  },
  cancelButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
  },
  searchButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
  },
}));

const getIdFromReference = (reference) => {
  return reference.split("/")[1];
};

const getServices = async (
  url,
  {
    serviceType,
    addressPostalCode,
    addressState,
    orgName,
    serviceCategory,
    language,
  },
  capabilities
) => {
  const params = {};
  if (capabilities?.IncludeAll) {
    params["_include"] = "*";
  }
  if (serviceType) {
    params["service-type"] = serviceType;
  }
  if (serviceCategory) {
    params["service-category"] = serviceCategory;
  }
  if (addressPostalCode) {
    params["address-postalcode"] = addressPostalCode;
  }
  if (addressState) {
    params["address-state"] = addressState;
  }
  if (orgName) {
    params["organization:Organization.name"] = orgName;
  }
  if (language) {
    params["language"] = language;
  }

  const response = await axiosInstance.get(url, {
    params,
  });
  return response?.data;
};

const getServiceTypes = async (url) => {
  if (url) {
    const result = await axiosInstance.get(url);
    return result?.data;
  }
  return [];
};

function FhirServicesSelectionDialog({ open, handleClose, handleSave }) {
  const classes = useStyles();
  const [serviceTypeSearch, setServiceTypeSearch] = useState("");
  const [serviceCategorySearch, setServiceCategorySearch] = useState("");
  const [addressPostalCodeSearch, setAddressPostalCodeSearch] = useState("");
  const [addressStateSearch, setAddressStateSearch] = useState("");
  const [languageSearch, setLanguageSearch] = useState("");
  const [orgNameSearch, setOrgNameSearch] = useState("");
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([
    "Adult",
    "Adolescent",
    "Family",
  ]);
  const [addressStates, setAddressStates] = useState([
    "MD",
    "DC",
    "VA",
    "WV",
    "DE",
  ]);
  const [addressPostalCodes, setAddressPostalCodes] = useState([
    "20000",
    "20303",
  ]);
  const [languages, setLanguages] = useState(["English", "Spanish"]);
  const [orgNames, setOrgNames] = useState([]);

  const handleResetAndClose = () => {
    setServiceTypeSearch("");
    setServiceCategorySearch("");
    setLanguageSearch("");
    setAddressPostalCodeSearch("");
    setAddressStateSearch("");
    setSelectedService(null);
    setOrgNameSearch("");
    handleClose();
  };

  const handleServiceTypeSearchChange = (event, newInputValue) => {
    setServiceTypeSearch(newInputValue);
  };

  const handleServiceCategorySearchChange = (event, newInputValue) => {
    setServiceCategorySearch(newInputValue);
  };

  const handleLanguageSearchChange = (event, newInputValue) => {
    setLanguageSearch(newInputValue);
  };

  const handleAddressPostalCodeSearchChange = (event, newInputValue) => {
    setAddressPostalCodeSearch(newInputValue);
  };

  const handleAddressStateSearchChange = (event, newInputValue) => {
    setAddressStateSearch(newInputValue);
  };

  const handleOrgNameSearchChange = (event, newInputValue) => {
    setOrgNameSearch(newInputValue);
  };

  const handleSearchServices = async (event) => {
    event.preventDefault();
    console.log("DEBUG handleSearchServices start");
    setIsSearching(true);
    const searchParams = {
      serviceType: serviceTypeSearch,
      serviceCategory: serviceCategorySearch,
      addressPostalCode: addressPostalCodeSearch,
      addressState: addressStateSearch,
      orgName: orgNameSearch,
      language: languageSearch,
    };
    console.log("DEBUG searchParams: ", searchParams);

    try {
      const servicesResponse = await API.getReferralServices(searchParams);

      if (servicesResponse) {
        setServices(parseBundleToServices(servicesResponse));
      }
    } catch (error) {
      console.log("Could not find services");
      setServices([]);
    }
    console.log("DEBUG handleSearchServices end");
    setIsSearching(false);
  };

  return (
    <>
      <Dialog
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        aria-labelledby="services-dialog"
        scroll="paper"
      >
        <DialogTitle id="services-dialog-title">Services Selection</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSearchServices}>
            <FormGroup row>
              <Autocomplete
                freeSolo
                options={serviceTypes.map((s) => s?.code)}
                variant="outlined"
                margin="dense"
                label="Type"
                fullWidth
                inputValue={serviceTypeSearch}
                onInputChange={handleServiceTypeSearchChange}
                renderInput={(params) => <TextField {...params} label="Type" />}
              />
            </FormGroup>
            <FormGroup row>
              <Autocomplete
                freeSolo
                options={serviceCategories}
                variant="outlined"
                margin="dense"
                label="Category"
                fullWidth
                inputValue={serviceCategorySearch}
                onInputChange={handleServiceCategorySearchChange}
                renderInput={(params) => (
                  <TextField {...params} label="Category" />
                )}
              />
            </FormGroup>
            <FormGroup row>
              <Autocomplete
                freeSolo
                options={orgNames}
                variant="outlined"
                margin="dense"
                label="Org Name"
                fullWidth
                inputValue={orgNameSearch}
                onInputChange={handleOrgNameSearchChange}
                renderInput={(params) => (
                  <TextField {...params} label="Org Name" />
                )}
              />
            </FormGroup>
            <FormGroup row>
              <Autocomplete
                freeSolo
                options={addressStates}
                variant="outlined"
                margin="dense"
                label="State"
                fullWidth
                inputValue={addressStateSearch}
                onInputChange={handleAddressStateSearchChange}
                renderInput={(params) => (
                  <TextField {...params} label="State" />
                )}
              />
            </FormGroup>
            <FormGroup row>
              <Autocomplete
                freeSolo
                options={addressPostalCodes}
                variant="outlined"
                margin="dense"
                label="Postal Code"
                fullWidth
                inputValue={addressPostalCodeSearch}
                onInputChange={handleAddressPostalCodeSearchChange}
                renderInput={(params) => (
                  <TextField {...params} label="Postal Code" />
                )}
              />
            </FormGroup>
            <FormGroup row>
              <Autocomplete
                freeSolo
                options={languages}
                variant="outlined"
                margin="dense"
                label="Language"
                fullWidth
                inputValue={languageSearch}
                onInputChange={handleLanguageSearchChange}
                renderInput={(params) => (
                  <TextField {...params} label="Language" />
                )}
              />
            </FormGroup>
            <FormGroup row>
              <Button
                color="primary"
                type="submit"
                disabled={isSearching}
                className={classes.searchButton}
              >
                Search
              </Button>
            </FormGroup>
          </form>
          {isSearching ? (
            <ThemeProvider theme={theme}>
              <CircularProgress />
            </ThemeProvider>
          ) : null}
          <FhirServicesTable
            services={services}
            selectedService={selectedService}
            setSelectedService={setSelectedService}
          />
          {selectedService ? (
            <FhirRefer
              service={selectedService}
              handleSave={handleSave}
              handleClose={handleResetAndClose}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

FhirServicesSelectionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default FhirServicesSelectionDialog;
