import {
  DEVELOPMENT_DB_FULLNAME,
  DEVELOPMENT_DB_ORGNAME,
  DEVELOPMENT_DB_USERNAME,
  login,
  logoutUrl,
} from "./constants";
import { InvalidTokenException, TokenExpiredException } from "./exceptions";
import { get, isEmpty } from "lodash";
import jwt_decode from "jwt-decode";
import jwt_encode from "jwt-encode";
import { shouldLogout } from "../services/MintAPI";
import md5 from "md5";
import { ConfigAPI } from "../services/ConfigAPI";

const ACL = (process.env.REACT_APP_USER_PERMISSIONS || "")
  .split(",")
  .map((permission) => permission.trim().toUpperCase())
  .filter((permission) => permission);
const PERMISSIONS = {
  canPostDocument() {
    return ACL.includes("DOCUMENT_POST");
  },
  isCuresEnabled() {
    return ACL.includes("CURES");
  },
  isPreventionLinkEnabled() {
    return ACL.includes("PREVENTION_LINK");
  },
  isPatientCarePlansEnabled() {
    return ACL.includes("PATIENT_CAREPLANS");
  },
  isPatientAssessmentsEnabled() {
    return ACL.includes("PATIENT_ASSESSMENTS");
  },
};

let queryingShouldLogout = false;

class Auth {
  permissions = PERMISSIONS;

  constructor(storage, key) {
    this.storage = storage;
    this.key = key;
    this.loggedInUser = null;
    this.customOrgAttributes = null;
    this.mintUserId = null;
  }

  getOrgName() {
    return this.loggedInUser?.userinfo?.orgName;
  }

  async getOrgCustomAttributes() {
    if (!this.customOrgAttributes) {
      this.customOrgAttributes = await ConfigAPI.getOrgCustomAttributes();
    }
    return this.customOrgAttributes;
  }

  async getCustomOrgAttribute(attrName) {
    return (await this.getOrgCustomAttributes(this.getOrgName()))[attrName];
  }

  async orgUsesManualMrnProvisioning() {
    return this.getCustomOrgAttribute("manual_mrn_provisioning");
  }

  async orgCanProvision() {
    return this.getCustomOrgAttribute("can_provision");
  }

  async orgIsCommunity() {
    return this.getCustomOrgAttribute("is_community_org");
  }

  login(user, callback) {
    this.loggedInUser = user;
    if (callback) {
      callback();
    }
  }

  logout = (logoutType, callback) => {
    const url = new URL(logoutUrl);
    if (this.loggedInUser?.token) {
      url.searchParams.append("token", this.loggedInUser.token);
    }
    if (logoutType) {
      url.searchParams.append("type", logoutType);
    }
    this.clearUser(callback);
    window.location = url;
  };

  clearLoginData = (callback) => {
    this.clearUser(callback);
  };

  clearUser = (callback) => {
    this.loggedInUser = null;
    this.storage.removeItem(this.key);
    if (callback) {
      callback();
    }
  };

  getTokenFromStorage() {
    if (process.env.REACT_APP_DEVELOPMENT_MODE === "true") {
      const now = Math.floor(Date.now() / 1000);
      return jwt_encode(
        {
          auth_type: "OIDC",
          userinfo: {
            name: DEVELOPMENT_DB_FULLNAME,
            preferred_username: DEVELOPMENT_DB_USERNAME,
            orgName: DEVELOPMENT_DB_ORGNAME,
            application_roles: null,
          },
          iss: "https://iam-dev.nicheaim.com/auth/realms/ehn-pgc",
          iat: now,
          exp: now + 3600,
        },
        "myscret"
      );
    }
    const token = this.storage.getItem(this.key);
    return token;
  }

  setTokenToStorage(token) {
    this.storage.setItem(this.key, token);
  }

  logoutIfRequired = (token) => {
    const _token = token || this.loggedInUser?.token;
    const printDebug = (_debug) => {
      // console.log("logoutIfRequired():", JSON.stringify(_debug, null, 2));
    };
    const debug = {
      ts: Date.now(),
      "token.md5": _token ? md5(_token) : null,
      querying: queryingShouldLogout,
      shouldLogoutResult: undefined,
      shouldLogout: undefined,
      logout: undefined,
      error: undefined,
    };
    if (!queryingShouldLogout) {
      queryingShouldLogout = true;
      if (_token) {
        shouldLogout(_token)
          .then((result) => {
            debug["shouldLogoutResult"] = result;
            printDebug(debug);
            queryingShouldLogout = false;
            if (result["shouldLogout"]) {
              debug["logout"] = true;
              printDebug(debug);
              this.logout(result.reason);
            }
          })
          .catch((error) => {
            debug["error"] = error.message;
            printDebug(debug);
            queryingShouldLogout = false;
          });
      }
    }
  };

  user = () => {
    if (!this.loggedInUser) {
      const token = this.getTokenFromStorage();
      if (token) {
        this.loggedInUser = this.parseToken(token);
      } else {
        return null;
      }
    }
    return this.loggedInUser;
  };

  get username() {
    return get(this.user(), "userinfo.preferred_username");
  }

  get fullName() {
    return get(this.user(), "userinfo.name");
  }

  get patient() {
    return get(this.user(), "patient");
  }

  tenant = () => {
    const userinfo = get(this.user(), "userinfo", null);
    return userinfo?.tenant ?? null;
  };

  token = () => {
    const accessToken = get(this.user(), "token", null);
    if (accessToken && process.env.REACT_APP_DEVELOPMENT_MODE !== "true") {
      const decodedToken = jwt_decode(accessToken);
      const currentEpochTime = Math.floor(new Date().getTime() / 1000);
      if (decodedToken.exp <= currentEpochTime) {
        console.error("Token is expired.");
        this.logout("TOKEN_EXPIRED");
      }
    }
    return accessToken;
  };

  header = () => {
    const token = this.token();
    return token ? { Authorization: `Bearer ${token}` } : {};
  };

  isAuthenticated(user) {
    if (!this.loggedInUser) {
      this.login(user);
    }
    return !!this.loggedInUser;
  }

  getTokenFromUrl(urlString = get(window, "location.href")) {
    const url = new URL(urlString);
    let fragment = url.hash;
    if (fragment.includes("?")) {
      fragment = fragment.substring(fragment.lastIndexOf("?") + 1);
    } else {
      fragment = fragment.replace("#", "?");
    }
    let token = this.getTokenFromUrlFragment(fragment);
    if (!token) {
      token = this.getTokenFromUrlFragment(url.search);
    }
    return {
      url,
      token,
    };
  }

  getTokenFromUrlFragment(fragment) {
    if (fragment) {
      const urlSearchParams = new URLSearchParams(fragment);
      let token = urlSearchParams.get("id_token");
      if (!token) {
        token = urlSearchParams.get("token");
      }
      return token;
    }
    return null;
  }

  parseToken(token) {
    let userToken;
    try {
      userToken = jwt_decode(token);
    } catch (error) {
      console.error(error);
      if (isEmpty(token)) {
        throw new InvalidTokenException("Token is empty.");
      } else {
        throw new InvalidTokenException("Token is invalid.");
      }
    }
    const expires = userToken.exp;
    if (!expires) {
      throw new InvalidTokenException("Token does not specify an expiration.");
    }
    const username = get(userToken, "userinfo.preferred_username");
    const email = get(userToken, "userinfo.email");
    if (!username && !email) {
      throw new InvalidTokenException(
        "Token does not specify a username or email."
      );
    }
    if (new Date().getTime() >= new Date(userToken.exp * 1000).getTime()) {
      throw new TokenExpiredException();
    }
    const authType = userToken?.auth_type;
    return {
      authType,
      token,
      userinfo: userToken.userinfo,
      expires,
      patient: userToken?.patient,
    };
  }

  get canSeeAllReferrals() {
    return true;
  }

  get usernameParameter() {
    if (process.env.REACT_APP_DEVELOPMENT_MODE === "true") {
      return `?username=${encodeURIComponent(DEVELOPMENT_DB_USERNAME)}`;
    }
    return "";
  }

  getLoginUrl() {
    const loginUrl = login + this.usernameParameter;
    return loginUrl;
  }
}

export default new Auth(sessionStorage, "userToken");
