import React from "react";
import Layout from "./Layout";
import { makeStyles } from "@material-ui/core/styles";
import { FhirReferralsViewer } from "./PatientViewer/FhirReferrals";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
  viewer: {
    position: "absolute",
    width: "80%",
    top: "111px",
    left: "50%",
    transform: "translateX(-50%)",
  },
}));

export default function ReferralManagement() {
  const classes = useStyles();
  return (
    <Layout className={classes.root}>
      <div className={classes.viewer}>
        <FhirReferralsViewer showAllReferrals={true} />
      </div>
    </Layout>
  );
}
