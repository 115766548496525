export const ALL_WILDCARD = "*";
export const EDIT = "edit";
export const ADD = "add";
export const DELETE = "delete";
export const VIEW = "view";
export const MENU = "menu";

class ACL {
  _currentPath;

  static separator = ".";

  constructor(path) {
    this._currentPath = path;
  }

  concat(...acls) {
    return concatACL(...[this.currentPath, ...acls]);
  }

  newChild(path) {
    return new ACL(`${this._currentPath}${ACL.separator}${path}`);
  }

  get currentPath() {
    return this._currentPath;
  }
}

export const concatACL = (...acls) => acls.join(ACL.separator);

export default ACL;
