import { referralsFormApiUrl } from "../utils/constants";
import { ErrorUtils } from "../utils/exceptions";
import { AxiosHelper } from "../utils/AxiosHelper";

const axiosInstance = ErrorUtils.configureAxiosForMintErrors(
  AxiosHelper.newInstance()
);

export default class FormRendererAPI {
  static async getReferralFormSize(formUUID) {
    const url = `${referralsFormApiUrl}/size/${formUUID}`;
    const result = await axiosInstance.get(url);
    return result?.data;
  }
}
