import auth from "./auth";
import axios from "axios";

const defaultOptions = {
  headers: {
    "Content-Type": "application/json",
  },
};

const getTenantHeader = () => {
  return auth.tenant()?.name ?? null;
};

export class AxiosBackendHelper {
  static newInstance(options) {
    const instance = axios.create({ ...defaultOptions, ...(options || {}) });
    instance.interceptors.request.use(
      (config) => {
        const tenantHeader = getTenantHeader();
        config.headers["Content-Type"] = "application/json";
        config.headers["Accept"] = "application/json";
        const token = auth.token();
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        if (tenantHeader) {
          config.headers["X-Tenant-ID"] = tenantHeader;
        }
        return config;
      },
      (error) => error
    );
    return instance;
  }
}

export class AxiosHelper {
  static newInstance(options) {
    const instance = axios.create({ ...defaultOptions, ...(options || {}) });
    instance.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => error
    );
    return instance;
  }
}
