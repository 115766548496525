import auth from "../auth";
import { isStringArray } from "../string.utils";

export const aclsLocalStorageKey = "userACLs";

export class PermissionsExtractionError extends Error {
  constructor(message) {
    super(message);
  }
}

export class ACLValidation {
  acls;
}

export function NO_ALLOWING_CONTAINER() {
  return {
    isAllowed(..._) {
      return false;
    },
    permissions: [],
  };
}

export class ACLValidationMode {
  static INCLUSIVE = "inclusive";
  static EXCLUSIVE = "exclusive";
}

export function getAclsFromStorage() {
  try {
    const acls = localStorage.getItem(aclsLocalStorageKey);
    if (!acls) return [];
    return JSON.parse(acls);
  } catch (error) {
    return [];
  }
}

export function getUserPermissions() {
  const token = auth.token();
  if (token) {
    // if (token && isValidToken(token)) {
    const acls = getAclsFromStorage();

    return newPermissionsContainer(acls, (dat) => dat);
  } else {
    return NO_ALLOWING_CONTAINER;
  }
}

export function getMatchingPermissions(
  assignedPermissionsSpec,
  requiredPermissions
) {
  if (!assignedPermissionsSpec || !requiredPermissions) {
    return [];
  }
  const regex = new RegExp(
    [
      "^",
      assignedPermissionsSpec.replace(/\./g, "\\.").replace(/\*/g, ".+"),
      "$",
    ].join("")
  );
  return requiredPermissions.filter((permission) => permission.match(regex));
}

/*Inclusive Mode = Will consider related(parents) ACL in the ACL hierarchy*/
/*Exclusive Mode = Will only consider acls being pass down as params*/
export function checkAclValidation(
  validation,
  mode = ACLValidationMode.INCLUSIVE
) {
  const userPermissions = getUserPermissions();

  const { acls: aclsToCheck } = validation;

  switch (mode) {
    case ACLValidationMode.INCLUSIVE:
      const isAclValid = aclsToCheck.filter((permission) =>
        userPermissions.isAllowed(permission)
      );
      return !!isAclValid.length;
    default:
      const userAssignedPermissions = new Set(userPermissions.permissions);
      return aclsToCheck.some((acl) => userAssignedPermissions.has(acl));
  }
}

export function isAllowed(assignedPermissionsSpecs, ...requiredPermissions) {
  if (
    !assignedPermissionsSpecs ||
    !requiredPermissions ||
    assignedPermissionsSpecs.length === 0 ||
    requiredPermissions.length === 0
  ) {
    return false;
  }
  const matchingPermissions = assignedPermissionsSpecs.reduce(
    (matchingPermissions, spec) => {
      getMatchingPermissions(spec, requiredPermissions).forEach(
        (permission) => {
          matchingPermissions.add(permission);
        }
      );

      return matchingPermissions;
    },
    new Set()
  );

  return requiredPermissions.some((permission) =>
    matchingPermissions.has(permission)
  );
}

export function newPermissionsContainer(
  permissionsSource,
  permissionsExtractor
) {
  if (!permissionsSource) {
    return NO_ALLOWING_CONTAINER;
  }

  let permissions;
  if (!permissionsExtractor) {
    if (isStringArray(permissionsSource)) {
      permissions = permissionsSource;
    } else {
      throw new PermissionsExtractionError(
        "Permissions extractor function was not provided."
      );
    }
  } else {
    try {
      permissions = permissionsExtractor(permissionsSource);
    } catch (error) {
      throw new PermissionsExtractionError(
        `Unable to extract permissions from source: "${error.message}"`
      );
    }
  }
  return {
    permissions,
    isAllowed(...requiredPermissions) {
      return isAllowed(permissions, ...requiredPermissions);
    },
  };
}
