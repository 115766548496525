import React, { useEffect, useState } from "react";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import colors from "../../utils/colors";
import auth from "../../utils/auth";
import { useField, useFieldUnpacked, useForm } from "../../utils/form-utils";
import moment from "moment";

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: "48%",
        position: "absolute",
        bottom: "10px",
      },
      svg: {
        color: colors.baseBlue,
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    width: 185,
  },
}));

const getBirthDate = (date) => {
  if (date) {
    return moment(date).format("YYYY-MM-DD");
  }
  return null;
};

const getDefaultMrn = (props) => {
  if (props.prefilledPatient) {
    if (props.auth.loggedInUser?.["authType"] === "SMART_ON_FHIR") {
      return (
        props.prefilledPatient?.patientSearchIds?.externalId ||
        props.prefilledPatient?.patientSearchIds?.resourceId
      );
    }
  }
  return null;
};

const isEmpty = (value) => !value || value.includes("undefined");

const ifNotEmpty = (value, isInvalid, errorMsg) => {
  return isEmpty(value) ? "" : isInvalid(value) ? errorMsg : "";
};

const zipCodeValidator = (zipCode) =>
  ifNotEmpty(
    zipCode,
    (zipCode) => !/^[0-9]{5}(?:-[0-9]{4})?$/.test(zipCode),
    "Invalid Zip Code."
  );

const birthDateValidator = (date) =>
  ifNotEmpty(
    date,
    (date) => moment(date).isSameOrAfter(new Date(), "day"),
    "Invalid birth date."
  );

function NewPatientDialog(props) {
  const classes = useStyles();
  const [useManualMrn, setUseManualMrn] = useState(false);
  const [usePrefilledMrn, setUsePrefilledMrn] = useState(false);
  const [canProvision, setCanProvision] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const form = useForm();
  const mrn = useField(
    form,
    "MRN",
    getDefaultMrn(props),
    useManualMrn || usePrefilledMrn
  );
  const givenName = useField(
    form,
    "First Name",
    props.prefilledPatient?.firstName
  );
  const familyName = useField(
    form,
    "Last Name",
    props.prefilledPatient?.lastName
  );
  const birthDate = useField(
    form,
    "Birth Date",
    getBirthDate(props.prefilledPatient?.birthDate),
    true,
    birthDateValidator
  );
  const [sex, handleSexChange, sexError, sexLabel] = useFieldUnpacked(
    form,
    "Sex",
    props.prefilledPatient?.sex
  );
  const streetAddress = useField(
    form,
    "Street Address",
    props.prefilledPatient?.line
  );
  const city = useField(form, "City", props.prefilledPatient?.city);
  const state = useField(form, "State", props.prefilledPatient?.state, true);
  const zip = useField(
    form,
    "Zip Code",
    props.prefilledPatient?.postalCode,
    true,
    zipCodeValidator
  );
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);

  useEffect(() => {
    (async () => {
      setUseManualMrn(await auth.orgUsesManualMrnProvisioning());
      setCanProvision(await auth.orgCanProvision());
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (props.auth.loggedInUser?.["authType"] === "SMART_ON_FHIR") {
      setUsePrefilledMrn(true);
    }
    console.log("DEBUG userinfo: ", props.auth.loggedInUser.userinfo);
  }, [props.auth]);

  const handleSave = async () => {
    setSaveError("");
    if (form.isInvalid) {
      return;
    }
    setSaving(true);

    const orgFhirId = props.auth.loggedInUser.userinfo.orgFhirId;
    console.log(`DEBUG orgFhirId: ${orgFhirId}`);

    const fhirPatient = {
      resourceType: "Patient",
      birthDate: birthDate.value,
      name: [
        {
          family: familyName.value,
          given: [givenName.value],
        },
      ],
      gender: sex,
      address: [
        {
          line: [streetAddress.value],
          city: city.value,
          state: state.value,
          postalCode: zip.value,
        },
      ],
      ...(orgFhirId && {
        managingOrganization: {
          reference: `Organization/${orgFhirId}`,
          type: "Organization",
        },
      }),
    };
    console.log("DEBUG fhirPatient: ", fhirPatient);

    let error = await props.handleSave(fhirPatient, mrn.value);
    if (error) {
      setSaving(false);
      setSaveError(error);
    }
  };

  const reset = () => {
    setSaving(false);
    form.reset();
  };

  const newTextField = (field, type, readOnly = false) => (
    <TextField
      required
      variant="outlined"
      margin="dense"
      className={classes.formControl}
      type={type}
      label={field.name}
      value={field.value}
      error={field.error !== ""}
      disabled={readOnly}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={field.changeHandler}
      helperText={
        field.error !== field.requiredErrorMsg ? field.error : undefined
      }
    />
  );

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      onExited={reset}
      disableBackdropClick
      maxWidth="xs"
    >
      <DialogTitle>Add Patient</DialogTitle>
      {isLoading ? null : (
        <>
          <DialogContent style={{ overflow: "hidden" }}>
            <div className={classes.root}>
              <Grid container spacing={3}>
                {useManualMrn && !usePrefilledMrn && (
                  <Grid item xs>
                    {newTextField(mrn)}
                  </Grid>
                )}
                {usePrefilledMrn && !useManualMrn ? (
                  <Grid item xs>
                    {newTextField(mrn, "text", true)}
                  </Grid>
                ) : null}
                <Grid item xs>
                  {newTextField(givenName)}
                </Grid>
                <Grid item xs>
                  {newTextField(familyName)}
                </Grid>
                <Grid item xs>
                  {newTextField(birthDate, "date")}
                </Grid>
                <Grid item xs>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    className={classes.formControl}
                    required
                    error={sexError}
                  >
                    <InputLabel htmlFor="sex">{sexLabel}</InputLabel>
                    <Select
                      value={sex}
                      onChange={handleSexChange}
                      label={sexLabel}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        name: "sex",
                        id: "sex",
                      }}
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="unknown">Unknown</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs>
                  {newTextField(streetAddress)}
                </Grid>
                <Grid item xs>
                  {newTextField(city)}
                </Grid>
                <Grid item xs>
                  {newTextField(state)}
                </Grid>
                <Grid item xs>
                  {newTextField(zip)}
                </Grid>

                {!canProvision && !getDefaultMrn(props) ? (
                  <Grid item xs>
                    <MuiAlert severity="error" elevation={6} variant="filled">
                      Your organization is not allowed to post patients
                    </MuiAlert>
                  </Grid>
                ) : null}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={props.handleClose}
              color="primary"
              disabled={saving}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              color="primary"
              disabled={
                form.isInvalid ||
                saving ||
                (!canProvision && !getDefaultMrn(props))
              }
            >
              Save
            </Button>
          </DialogActions>
        </>
      )}

      {saving && (
        <ThemeProvider theme={theme}>
          <div>
            <CircularProgress />
          </div>
        </ThemeProvider>
      )}
      {saveError && (
        <MuiAlert severity="error" elevation={6} variant="filled">
          {saveError}
        </MuiAlert>
      )}
    </Dialog>
  );
}

NewPatientDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  prefilledPatient: PropTypes.any,
  auth: PropTypes.any,
};

export default NewPatientDialog;
